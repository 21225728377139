<template>
    <div>
        <van-row class="item-card" v-for="(item,index) in data" v-bind:key="index">
            <van-col offset="1" span="23">
                <van-row class="title van-ellipsis">
                    <van-col span="24" class="van-ellipsis">{{ item.customerName }}</van-col>
                </van-row>
                <van-row v-if="item.title" class="title van-ellipsis">
                  <van-col span="24" class="van-ellipsis">{{ item.title }}</van-col>
                </van-row>
                <van-row v-if="item.levelss" class="info">
                  客户等级：{{item.levelss}}
                </van-row>
                <van-row v-if="item.khfzr" class="info">
                  客户负责人：<span class="push-person">{{item.khfzr}}</span>
                </van-row>
                <van-row v-if="item.vessel" class="info">
                  船名：{{item.vessel}}
                </van-row>
                <van-row v-if="item.startTime" class="info">
                  年协起始时间：{{item.startTime}}
                </van-row>
                <van-row v-if="item.endTime" class="info">
                  年协终止时间：{{item.endTime}}
                </van-row>
                <van-row v-if="item.leavetime" class="info">
                    离港时间：{{item.leavetime}}
                </van-row>
                <van-row v-if="item.oaType" class="info">
                  状态：{{item.oaType}}
                </van-row>
                <van-row v-if="item.creTime" class="info">
                  推送日期：{{item.creTime}}
                </van-row>
                <van-row v-if="item.content" class="info">
                  <van-col span="5">
                    推送内容：
                  </van-col>
                  <van-col span="19">
                    <span class="wrap">{{ item.content }}</span>
                  </van-col>
                </van-row>
            </van-col>
        </van-row>
    </div>
</template>

<script>
    export default {
        name: "InfoCard",
        props: {
            data: {type: Array}
        },
        data() {
            return {}
        },
        methods: {
          cardClick(item) {
            console.log(item)
            if (item && item.to && item.to.length > 0) {
              this.$emit('cardClickCallBack', item);
            }
          },
        },
        watch: {}
    }
</script>

<style scoped>
    .push-person {
        color: #007fff !important;
    }

    .item-card {
        background: #fff;
        padding: 5px 0;
        margin-top: 1px;
        text-align: left;
    }

    .title {
        font-size: 16px;
        color: #007fff;
        margin-top: 10px;
    }

    .info {
        padding: 5px 0;
        font-size: 10px;
        color: #999999;
    }

    .content{
      font-size: 10px;
    }

    .is-read {
      font-size: 18px;
      font-weight: bolder;
      margin-top: 5px;
      color: red;
    }
</style>

<style>
    .item-card {
      white-space: pre-wrap;
    }

    .item-card .wrap {
      /*word-wrap: break-word;*/
      /*word-break: break-all;*/
      white-space: pre-wrap;
    }
</style>
