<template>
  <div class="warning-panel-detail">
    <van-tabs sticky v-model="activeName" @click="onClick" border sticky :swipeable=false>
      <van-tab name="01" style="background-color: white">
        <div slot="title">
          今日数据
          <!--          <span v-if="circle2 != 0" class="circle">+{{ circle2 }}</span>-->
        </div>
        <div v-if="this.list && this.list.length>=1"
             :style="{height: freshHeight + 180 +'px',overflowY: 'scroll', boxSizing: 'border-box'}">
          <warning-card :data="this.list" style="margin-bottom: 50px"/>
        </div>
        <div v-else style="background-color: #FFFFFF">
          <van-empty image="error" description="没有数据"/>
        </div>
      </van-tab>
      <van-tab name="02" style="background-color: white ">

        <div slot="title">
          历史数据
        </div>
        <van-sticky>
          <van-calendar v-model="show"
                        :min-date="minDate"
                        :max-date="maxDate"
                        position="top" type="range" @confirm="onConfirm"/>

          <van-row>
            <van-search v-model="selectFzrValue" placeholder="请输入负责人" @cancel="onCancel()" show-action/>
          </van-row>
          <van-row span="24">
            <div v-for="(item, index) in timeSpan" v-bind:key="item.id" :data="item">
              <van-col offset="1" span="5">
                <div class="timeSpan" @click="selectTime(item,index)" :value="date"
                     :style="{color: item.use ? item.color : '#827E7E', background: item.use ? item.backColor: '#F3F3F3'}">
                  {{ item.title }}
                </div>
              </van-col>
            </div>
          </van-row>
        </van-sticky>
        <div v-if="this.list && this.list.length>=1" ref="childRef"
             :style="{height: freshHeight +'px',overflowY: 'scroll'}">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                loading-text="加载中"
                :finished="finished"
                finished-text="没有更多了"
                @load="nextPage"
            >
              <warning-card :data="this.list"/>
            </van-list>
          </van-pull-refresh>
        </div>
        <div v-else style="background-color: #FFFFFF">
          <van-empty image="error" description="没有数据"/>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import {getInfolistDetail} from '@/api/warningPanel'
import WarningCard from "@/components/Card/WarningCard";
import {Toast} from "vant";

export default {
  name: "WarningPanelDetail",
  components: {WarningCard},
  data() {
    return {
      minDate: '',
      maxDate: new Date(2030, 12, 31),
      value1: '',
      activeName: '01',
      list: [],
      freshHeight: 0,
      selectFzrValue: '',
      showPopup: false,
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页个数
      loading: false, // 加载下一页
      finished: false, // 是否加载完了
      refreshing: false,
      date: '',
      show: false,
      curYear: '',
      curQuarter: '',
      curMonth: '',
      custom: '',
      timeSpan: [
        {
          title: '当年',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: new Date().getFullYear(),
          use: false,
        },
        {
          title: '当季度',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: '',
          use: false,
        },
        {
          title: '当月',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: new Date().getMonth() + 1,
          use: false,
        },
        {
          title: '自定义',
          color: '#4DB8FF',
          backColor: '#EAF4FF',
          value: '',
          use: false,
        },
      ],
    }
  },
  watch: {
    //监听负责人名头
    'selectFzrValue': function () {
      this.list = [];
      this.$refs.childRef.scrollTop = 0;
      this.init('02');
    },
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 180
    this.init(this.activeName)
    var curDate = (new Date()).getTime()
    // 将半年的时间单位换算成毫秒
    var halfYear = 365 / 2 * 24 * 3600 * 1000
    // 半年前的时间（毫秒单位）
    var pastResult = curDate - halfYear
    // 日期函数，定义起点为半年前
    this.minDate = new Date(pastResult)
  },
  methods: {
    onClick(name, title) {
      this.selectFzrValue = ''
      this.clearTime()
      this.currentPage = 1
      this.pageSize = 10
      // this.$refs.childRef.scrollTop = 0;
      this.list = []
      this.init(name)
    },
    init(name) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      if (name == '01') {
        getInfolistDetail({
          type: this.$route.params.id,
          time: name,
          current: this.currentPage,
          size: this.pageSize,
          khfzr: this.selectFzrValue,
          curYear: '',
          curQuarter: '',
          curMonth: '',
          custom: '',
        }).then(res => {
          console.log(res)
          if (res.length > 0) {
            this.list = res
            Toast.success('加载成功');
          } else {
            Toast.success('暂无数据');
          }
        }).catch(err => {
          console.log(err)
          Toast.fail('加载失败');
        })
      } else {
        this.onRefresh()
      }
    },
    onRefresh() {
      this.currentPage = 1
      this.list = []
      this.finished = false
      this.refreshing = true
      this.loading = true
      this.getData()
    },
    // 加载下一页
    nextPage() {
      setTimeout(() => {
        if (!this.finished) {
          this.loading = false
          this.currentPage += 1
          this.getData()
        }
      }, 500)
    },
    // 下拉刷新
    getData() {
      this.loading = true
      getInfolistDetail({
        type: this.$route.params.id,
        time: name,
        current: this.currentPage,
        size: this.pageSize,
        khfzr: this.selectFzrValue,
        curYear: this.curYear,
        curQuarter: this.curQuarter,
        curMonth: this.curMonth,
        custom: this.custom,
      }).then(res => {
        this.loading = false
        if (res.length > 0) {
          // 如果下拉刷新，那么数据清空重新获取第一页
          if (this.refreshing) {
            this.list = []
            this.refreshing = false
          }
          console.log(res)
          res.forEach(item => {
            this.list.push(item)
          })
          this.listRow = this.list.length
          // 如果数据加载完毕，finished 标志位置为 true，滑到页面底部则不会再触发 nextPage() 方法了。currentPage 重置为 1
          if (res.length < this.pageSize - 1) {
            this.finished = true
          } else {
            this.finished = false
          }
          Toast.success('加载成功');
        } else {
          Toast.success('暂无数据');
        }
      }).catch(err => {
        console.log(err)
        Toast.fail('加载失败');
      })
    },
    onConfirm(date) {
      this.custom = ''
      const [start, end] = date;
      this.show = false;
      this.date = `${this.formatDate(start)}至${this.formatDate(end)}`;
      console.log(this.date)
      this.custom = this.date
      this.onRefresh()
    },
    selectTime(item, index) {
      this.clearTime()
      //改变子组件滚动条高度
      // this.$refs.childRef.scrollTop = 0;
      item.use = true;
      this.setIndex(item, index)
    },
    clearTime() {
      for (let i = 0; i < this.timeSpan.length; i++) {
        this.timeSpan[i].use = false;
      }
      this.curYear = '';
      this.curQuarter = '';
      this.curMonth = '';
      this.custom = '';
    },
    onCancel() {
      this.clearTime()
      this.selectFzrValue = ''
      this.init();
    },
    setIndex(item, index) {
      switch (index) { //想要判断的变量
        case 0: //状态一
          this.curYear = item.value
          this.onRefresh()
          break
        case 1: //状态二
          this.curQuarter = 'yes'
          this.onRefresh()
          break
        case 2://状态三
          this.curMonth = item.value
          this.onRefresh()
          break
        case 3:
          this.show = true
          break
      }
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
  }
}
</script>

<style scoped>
.warning-panel-detail {
  /*height: 2000px;*/
}

.warning-panel-detail .timeSpan {
  padding-top: 5px;
  width: 70px;
  height: 30px;
  background-color: #F3F3F3;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  vertical-align: middle
}
</style>